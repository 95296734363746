import React from 'react';
// import { MDBAnimation } from "mdbreact";
// import {Link} from "gatsby";
// import 'bootstrap';
import {navigate} from 'gatsby'; // import navigate from gatsby
import {Helmet} from 'react-helmet';
import Background from '../../assets/images/services/game-development/topbg-unity-game-development.jpg';
import beatbugs from '../../assets/images/services/game-development/beatbugs-background.png';

require('./detailservices.css');
const $ = typeof window !== `undefined` ? require('jquery') : null;

class GameDevelopment extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $(document).ready(() => {
      $('#pre-kickoff-vertical').fadeIn();
      $('#pre-kickoff').addClass('pre-kickoff-active');
      $('.card').on('click', function (e) {
        const id = $(this).attr('id');
        const description = ['pre-kickoff', 'code', 'increment', 'delivery'];
        description.forEach((Id) => {
          $(`#${  Id  }-description`).hide();
          $(`#${  Id  }-vertical`).hide();
          $(`#${  Id}`).removeClass(`${Id  }-active`);
        });
        $(`#${  id  }-description`).fadeIn();
        $(`#${  id  }-vertical`).fadeIn();
        $(`#${  id}`).addClass(`${id  }-active`);
      });
    });
    function addMargin() {
      window.scrollTo(0, window.pageYOffset - 80);
    }
    window.addEventListener('hashchange', addMargin);
  }

  shouldComponentUpdate() {
    // remove when no jquery is used
    return false;
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Game Development Services | Tintash</title>
        </Helmet>
        <div>
          <section
            className="service-individual-page content-section-header"
            style={{backgroundImage: `url(${Background})`}}
          >
            <div className="container d-flex h-100">
              <div className="row justify-content-center align-items-center">
                <div className="col-12">
                  <h1 className="latoBlack text-left text-white heading">Game Development</h1>
                  <p className="latoRegular text-left text-white description mb-0">
                    With over ten years of experience in mobile games industry, expert leadership
                    and top notch processes we have been developing hit mobile games that keep
                    people addicted to their screens for hours.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="service-text-banner">
            {/* Tag line of mobile development */}
            <div className="service-unicorn d-flex align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-12 slide-top service-text">
                    <span className="latoLight heavy-lift">Heavy-lifting for </span>
                    <span className="latoBold tag-line">Startups Unicorns </span>
                    <span className="latoLight heavy-lift connect">&amp;</span>
                    <span className="latoBold tag-line">Fortune 500s</span>
                  </div>
                  <div className="col-lg-4 col-12 clients-logos">
                    <div className="text-center unicorn-client">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/fortune-500/bedbathbeyond.png')
                            .default
                        }
                        alt="bedbathbeyond"
                      />
                      <p className="description latoBold mb-0">Fortune 500</p>
                    </div>
                    <div className="text-center unicorn-client">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/fortune-500/decorist.png')
                            .default
                        }
                        alt="decorist"
                      />
                      <p className="description latoBold mb-0">Acquired by Fortune 500</p>
                    </div>
                    <div className="text-center unicorn-client mb-0">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/fortune-500/lifeprint.png')
                            .default
                        }
                        alt="lifeprint"
                      />
                      <p className="description latoBold mb-0">Apple’s Retail Partner</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Tag line of mobile development */}
            {/* Game development section filter */}
            <div className="service-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <a href="#services" className="services__sections-anchor">
                      Capabilities
                    </a>
                  </div>
                  <div className="col-lg-3 col-6">
                    <a href="#process" className="services__sections-anchor">
                      Process
                    </a>
                  </div>
                  <div className="col-lg-3 col-6 text-lg-center">
                    <a href="#project" className="services__sections-anchor">
                      Featured Project
                    </a>
                  </div>
                  <div className="col-lg-3 col-6 text-lg-right">
                    <a href="#client" className="services__sections-anchor">
                      Client Engagements
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Game development section filter */}
            <div id="services" className="service-capabilities">
              {/* Capabilities */}
              <div className="capabilities">
                <div className="container ">
                  <img
                    width={74}
                    height={74}
                    src={
                      require('../../assets/images/services/main-page/unity-game-development-hover.svg')
                        .default
                    }
                  />
                  <h1 className="heading latoBold mb-0">Game Development Capabilities</h1>
                  <div className="row services">
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#unity-development">Unity Game Development</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#cocos-development">Cocos 2Dx</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#gameart">Game Art</a>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                      <a href="#qa">QA and Testing</a>
                    </div>
                  </div>
                </div>
              </div>
              {/* Capabilities */}
              {/* Game development services */}
              <div className="container">
                {/* Unity Game Development */}
                <div className="individual step-1" id="unity-development">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid #ffb100'}}
                      >
                        Unity Game Development
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        Tintash has developed a variety of games and applications in Unity over the
                        years, some of which have been featured on iOS App Store and Google Play
                        Store. Our games are spread across multiple genres including hyper-casual,
                        educational, adventure, platformer, puzzle, etc. We have also created
                        several applications in Unity including Decorist Room Builder,
                        photorealistic 3D interior design application.
                      </p>
                      <p className="description">
                        We have a team of Unity tech leads, senior engineers and architects with a
                        diverse skill-set in Unity. The cross-platform nature of Unity makes it very
                        easy for our developers to quickly create and deploy games on several
                        platforms at the same time including iOS, Android, WebGL, etc. using a
                        single codebase.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12 pl-lg-0 ">
                          <p className="text-left expertise">Unity UI</p>
                          <p className="text-left expertise">Unity multiplayer</p>
                          <p className="text-left expertise">2D and 3D physics</p>
                          <p className="text-center expertise">Animations</p>
                          <p className="text-center expertise">Mecanim</p>
                          <p className="text-left expertise">Particle system</p>
                          <p className="text-left expertise">Shaders</p>
                          <p className="text-left expertise">Lighting</p>
                          <p className="text-left expertise">Audio</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Navigation and path finding</p>
                          <p className="text-left expertise">Asset bundles</p>
                          <p className="text-left expertise">
                            Backend integration (server communication, data parsing, etc.)
                          </p>
                          <p className="text-left expertise">
                            Memory, graphics and performance optimisation
                          </p>
                          <p className="text-left expertise">Native plugins creation</p>
                          <p className="text-left expertise">3rd party plugins integration</p>
                          <p className="text-left expertise">Unity services</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title text-left">
                              <a
                                className="accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapseTwo"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapseTwo" className="panel-collapse collapse">
                            <div className="panel-body step-1">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <p className="text-left expertise">Unity UI</p>
                                  <p className="text-left expertise">Unity multiplayer</p>
                                  <p className="text-left expertise">2D and 3D physics</p>
                                  <p className="text-center expertise">Animations</p>
                                  <p className="text-center expertise">Mecanim</p>
                                  <p className="text-left expertise">Particle system</p>
                                  <p className="text-left expertise">Shaders</p>
                                  <p className="text-left expertise">Lighting</p>
                                  <p className="text-left expertise">Audio</p>
                                  <p className="text-left expertise">Navigation and path finding</p>
                                </div>
                                <div className="col-12 col-md-6">
                                  <p className="text-left expertise">Asset bundles</p>
                                  <p className="text-left expertise">
                                    Backend integration (server communication, data parsing, etc.)
                                  </p>
                                  <p className="text-left expertise">
                                    Memory, graphics and performance optimisation
                                  </p>
                                  <p className="text-left expertise">Native plugins creation</p>
                                  <p className="text-left expertise">
                                    3rd party plugins integration
                                  </p>
                                  <p className="text-left expertise">Unity services</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Unity Game Development */}
                {/* Cocos2d/Cocos2d-x Game Development */}
                <div className="individual step-2" id="cocos-development">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid  #0ec573'}}
                      >
                        Cocos 2Dx
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <p className="description">
                        Cocos2d/Cocos2d-x is a 2D game engine that we used extensively for game
                        development before Unity gained popularity. Our team has a very strong grip
                        on Cocos2d/Cocos2d-x engine. We started creating games in Cocos2d/Cocos2d-x
                        over 10 years ago and created titles such as Bam Bam Dash, Fish Frenzy, etc.
                        that topped the charts back then. Over the years we developed lots of 2D
                        games using this engine, a few of them got installs as high as 50 million
                        and got featured in iOS App Store and Google Play Store. Our biggest success
                        in Cocos2d/Cocos2d-x include all titles of Stick Cricket.
                      </p>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 pl-lg-0 col-12">
                          <p className="text-left expertise">
                            Responsive UI for all screen resolutions
                          </p>
                          <p className="text-left expertise">Animations and tweens</p>
                          <p className="text-left expertise">Physics</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Spine</p>
                          <p className="text-left expertise">3rd party SDK integrations</p>
                          <p className="text-left expertise">
                            Deployment on iOS, Android, Windows and Tizen platforms
                          </p>
                          <p className="text-left expertise">Memory and performance optimisation</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className=" accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapse"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapse" className="panel-collapse collapse">
                            <div className="panel-body step-2">
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <p className="text-left expertise">
                                    Responsive UI for all screen resolutions
                                  </p>
                                  <p className="text-left expertise">Animations and tweens</p>
                                  <p className="text-left expertise">Physics</p>
                                </div>
                                <div className="col-12 col-md-6">
                                  <p className="text-left expertise">Spine</p>
                                  <p className="text-left expertise">3rd party SDK integrations</p>
                                  <p className="text-left expertise">
                                    Deployment on iOS, Android, Windows and Tizen platforms
                                  </p>
                                  <p className="text-left expertise">
                                    Memory and performance optimisation
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Cocos2d/Cocos2d-x Game Development */}
                {/* Game Art */}
                <div className="individual step-1" id="gameart">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid #914df6'}}
                      >
                        Game Art
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <div className="vertical-line">
                        <p className="description">
                          Tintash has created a variety of games from 2D role-playing to Known IPs
                          3D Karaoke Game and delivered a wide range of expertise with a focus on
                          quality, high definition, and aesthetics. Our art and design services are
                          designed to encompass every part of the games development process, from
                          pre-production concept design and sketching right through to promotional
                          and marketing imagery post-release. Our teams work closely with clients to
                          get a deep understanding of how they want their games to look and feel so
                          that we can truly make their dreams a reality.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12 pl-lg-0">
                          <p className="text-left expertise">Concept Art</p>
                          <p className="text-left expertise">Illustrations</p>
                          <p className="text-left expertise">Characters</p>
                          <p className="text-left expertise">Animations</p>
                          <p className="text-left expertise">UI</p>
                          <p className="text-left expertise">Environment</p>
                          <p className="text-left expertise">VFX</p>
                          <p className="text-left expertise">Storyboarding</p>
                          <p className="text-left expertise">Pre-production imagery</p>
                          <p className="text-left expertise">Promotional images</p>
                          <p className="text-left expertise">Marketing assets</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Character Modeling</p>
                          <p className="text-left expertise">Props</p>
                          <p className="text-left expertise">Environment</p>
                          <p className="text-left expertise">Texturing</p>
                          <p className="text-left expertise">Scene Lighting</p>
                          <p className="text-left expertise">Unwrapping</p>
                          <p className="text-left expertise">Rigging</p>
                          <p className="text-left expertise">Animation</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className=" accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#collapse-dev"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="collapse-dev" className="panel-collapse collapse">
                            <div className="panel-body step-1">
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">Concept Art</p>
                                  <p className="text-left expertise">Illustrations</p>
                                  <p className="text-left expertise">Characters</p>
                                  <p className="text-left expertise">Animations</p>
                                  <p className="text-left expertise">UI</p>
                                  <p className="text-left expertise">Environment</p>
                                  <p className="text-left expertise">VFX</p>
                                  <p className="text-left expertise">Storyboarding</p>
                                  <p className="text-left expertise">Pre-production imagery</p>
                                  <p className="text-left expertise">Promotional images</p>
                                  <p className="text-left expertise">Marketing assets</p>
                                </div>
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">Character Modeling</p>
                                  <p className="text-left expertise">Props</p>
                                  <p className="text-left expertise">Environment</p>
                                  <p className="text-left expertise">Texturing</p>
                                  <p className="text-left expertise">Scene Lighting</p>
                                  <p className="text-left expertise">Unwrapping</p>
                                  <p className="text-left expertise">Rigging</p>
                                  <p className="text-left expertise">Animation</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Game Art */}
                {/* QA and Testing */}
                <div className="individual step-1" id="qa">
                  <div className="row w-100">
                    <div className="col-12">
                      <h1
                        className="heading mb-0 latoBold"
                        style={{borderLeft: '28px solid #ffb100'}}
                      >
                        QA and Testing
                      </h1>
                    </div>
                    <div className="col-lg-5 col-12">
                      <div className="vertical-line">
                        <p className="description">
                          We consider quality assurance and testing absolutely pivotal to the
                          success of every project we work on. We ensure end-to-end user
                          satisfaction by involving our team members at the very beginning of every
                          project. The testing process includes a combination of automated and
                          manual tests to ensure your product runs smoothly across devices.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 expertise-section">
                      <h2 className="expertise-heading text-left">Expertise</h2>
                      <div className="row">
                        <div className="col-lg-6 col-12 pl-lg-0">
                          <p className="text-left expertise">Functional testing</p>
                          <p className="text-left expertise">White and black box testing</p>
                          <p className="text-left expertise">Selenium</p>
                        </div>
                        <div className="col-lg-6 col-12 pl-lg-4">
                          <p className="text-left expertise">Appium</p>
                          <p className="text-left expertise">Espresso UI testing</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12  expertise-section-mobile">
                      <div className="panel-group" id="accordion">
                        <div className="panel panel-default">
                          <div className="panel-heading">
                            <h4 className="panel-title  text-left">
                              <a
                                className=" accordion-toggle collapsed expertise-heading"
                                data-toggle="collapse"
                                href="#qa-testing"
                              >
                                Expertise
                              </a>
                            </h4>
                          </div>
                          <div id="qa-testing" className="panel-collapse collapse">
                            <div className="panel-body step-1">
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">Functional testing</p>
                                  <p className="text-left expertise">White and black box testing</p>
                                  <p className="text-left expertise">Selinium</p>
                                  <p className="text-left expertise">Appium</p>
                                </div>
                                <div className="col-md-6 col-12">
                                  <p className="text-left expertise">Espresso UI testing</p>
                                  <p className="text-left expertise">JUnit</p>
                                  <p className="text-left expertise">XC Test</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* QA and Testing */}
              </div>
              {/* Game development services */}
            </div>
            {/* Game development Process */}
            <div id="process" className="development-process">
              {/* dev process for desktop */}
              <div className="container dev-process-desktop">
                <div className="row ">
                  <div className="col-12">
                    <h1 className="heading latoBold">Game Development Process</h1>
                  </div>
                  {/* pre-kickoff */}
                  <div className="col-lg-3 col-12 d-flex">
                    <div id="pre-kickoff" className="card pre-kickoff w-100">
                      <div className="card-head">
                        <p className="float-right latoBlack">1</p>
                      </div>
                      <div className="card-body">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/development-process/discover.png')
                              .default
                          }
                        />
                        <p className="description">Pre-kickoff</p>
                      </div>
                      <div className="card-footer" />
                      <div
                        id="pre-kickoff-vertical"
                        className="vertical-line"
                        style={{borderLeft: '3px dashed #0093FF'}}
                      />
                    </div>
                  </div>
                  {/* pre-kickoff */}
                  {/* kickoff */}
                  <div className="col-lg-3 col-12 d-flex">
                    <div id="code" className="card code w-100">
                      <div className="card-head">
                        <p className="float-right latoBlack">2</p>
                      </div>
                      <div className="card-body">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/development-process/code.png')
                              .default
                          }
                        />
                        <p className="description">Kickoff</p>
                      </div>
                      <div className="card-footer" />
                      <div
                        id="code-vertical"
                        className="vertical-line"
                        style={{borderLeft: '3px dashed #0EC573'}}
                      />
                    </div>
                  </div>
                  {/* kickoff */}
                  {/* Increment */}
                  <div className="col-lg-3 col-12 d-flex">
                    <div id="increment" className="card increment w-100">
                      <div className="card-head">
                        <p className="float-right latoBlack">3</p>
                      </div>
                      <div className="card-body">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/development-process/increment.png')
                              .default
                          }
                        />
                        <p className="description mb-0">Increment &amp; Release Cycles</p>
                      </div>
                      <div className="card-footer" />
                    </div>
                    <div
                      id="increment-vertical"
                      className="vertical-line"
                      style={{borderLeft: '3px dashed #F6654D'}}
                    />
                  </div>
                  {/* Increment */}
                  {/* Delivery */}
                  <div className="col-lg-3 col-12 d-flex">
                    <div id="delivery" className="card delivery in w-100">
                      <div className="card-head">
                        <p className="float-right latoBlack">4</p>
                      </div>
                      <div className="card-body">
                        <img
                          className="img-fluid"
                          src={
                            require('../../assets/images/services/development-process/milestone.png')
                              .default
                          }
                        />
                        <p className="latoBold description mb-0">Delivery Milestones </p>
                      </div>
                      <div className="card-footer" />
                      <div
                        id="delivery-vertical"
                        className="vertical-line"
                        style={{borderLeft: '3px dashed #FFB100'}}
                      />
                    </div>
                  </div>
                  {/* Delivery */}
                  {/*
               ----------------------------------
               ------KICK OFF DESCRIPTION--------
               ----------------------------------
                */}
                  {/* Pre-Kickoff */}
                  <div className="col-12">
                    <div id="pre-kickoff-description" className="process-description">
                      <div className="row">
                        <div className="pl-3">
                          <div className="circle" style={{backgroundColor: '#0093FF'}} />
                        </div>
                        <div className="col-11 pl-0">
                          <div className="description">
                            <h1 className="heading latoBlack mb-3">Pre Kick-off</h1>
                            <p className="latoRegular">
                              We evaluate the game idea and understand the client's requirements, as
                              well as any time and budgetary constraints. Together we evolve the
                              idea into one or multiple game concepts.
                            </p>
                            <p className="latoRegular">
                              After finalising the game concept, we create a comprehensive initial
                              game design document that details the art style, UI flow, core
                              mechanics, game loop, monetization plan and other details. This
                              document serves as a starting point for production.
                            </p>
                            <p className="latoRegular">
                              We do a detailed analysis of all the use cases and features of the
                              game based on the client approved GDD and create a production plan.
                              The plans includes a backlog of user stories, time and cost
                              estimation, defining milestones and releases and allocating resources.
                            </p>
                            <p className="latoRegular">
                              <a href="/project-delivery-process" className="mr-1">
                                {' '}
                                Read More
                              </a>
                              about our pre kick-off delivery process.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pre-Kickoff */}
                  {/* kickoff */}
                  <div className="col-12">
                    <div
                      id="code-description"
                      className="process-description"
                      style={{display: 'none'}}
                    >
                      <div className="row">
                        <div className="pl-3">
                          <div className="circle" style={{backgroundColor: '#0EC573'}} />
                        </div>
                        <div className="col-11 pl-0">
                          <div className="description">
                            <h1 className="heading latoBlack mb-3">Kick-off</h1>
                            <p className="latoRegular">
                              We use modified Scrum for project management and delivery of each
                              project.
                            </p>
                            <p className="latoRegular">
                              <a href="/modified-scrum-model" className="mr-1">
                                {' '}
                                Read More
                              </a>
                              on why we modify the Scrum model for each project.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* kickoff */}
                  {/* Increment */}
                  <div className="col-12">
                    <div
                      id="increment-description"
                      className="process-description"
                      style={{display: 'none'}}
                    >
                      <div className="row">
                        <div className="pl-3">
                          <div className=" circle" style={{backgroundColor: '#F6654D'}} />
                        </div>
                        <div className="col-11 pl-0">
                          <div className="description col-11">
                            <h1 className="heading latoBlack mb-3">Increment and Release cycles</h1>
                            <p className="latoRegular">
                              A sprint is typically one or two weeks long. At the end of each sprint
                              we deliver the verified and tested increment (build) to the client
                              along with the sprint report and QA report. We always appreciate and
                              welcome client feedback. Any given feedback is discussed with the
                              project team and then incorporated in future increments.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Increment */}
                  {/* Delivery */}
                  <div className="col-12">
                    <div
                      id="delivery-description"
                      className="process-description"
                      style={{display: 'none'}}
                    >
                      <div className="row">
                        <div className="pl-3">
                          <div className="circle" style={{backgroundColor: '#FFB100'}} />
                        </div>
                        <div className="col-11 pl-0">
                          <div className="description">
                            <h1 className="heading latoBlack mb-3">Milestone Delivery</h1>
                            <p className="latoRegular">
                              Once all the development work is completed and approved by the client
                              we submit the game on iOS App Store and Android Play Store.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Delivery */}
                </div>
              </div>
              {/* dev process for desktop */}
              {/* dev process for mobile */}
              <div className="container dev-process-mobile py-5">
                <div className="row">
                  <div className="col-12">
                    <h1 className="heading latoBold">Game Development Process</h1>
                  </div>
                  <div className="col-12">
                    <div className="panel-group" id="accordion">
                      {/* pre kickoff */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-pre"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/discover.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Pre-kickoff</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-pre" className="panel-collapse collapse">
                          <div className="panel-body step-">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description">
                                      <h1 className="heading latoBlack mb-3">Pre Kick-off</h1>
                                      <p className="latoRegular">
                                        The journey of game development at Tintash begins with
                                        evaluating the game idea and understanding the client's
                                        requirements as well as any time and budgetary constraints.
                                        Together with the client we evolve that idea into one or
                                        multiple game concepts.
                                      </p>
                                      <p className="latoRegular">
                                        Once a game concept is finalized we create a comprehensive
                                        initial game design document (GDD) that details art style
                                        and look and feel of the game, UI flow, core mechanics, game
                                        loop, progression, in-game economy and monetization plan.
                                        The GDD, once approved by the client, serves as a starting
                                        point for production.
                                      </p>
                                      <p className="latoRegular">
                                        We do a detailed analysis of all the use cases and features
                                        of the game based on the client approved GDD and create a
                                        production plan. This includes creating a backlog of user
                                        stories, time and cost estimation, defining milestones and
                                        releases and allocating resources.
                                      </p>
                                      <p className="latoRegular">
                                        <a href="/project-delivery-process" className="mr-1">
                                          {' '}
                                          Read More
                                        </a>
                                        about our pre kick-off delivery process.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group" id="accordion">
                      {/* Code */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-code"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/code.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Kickoff</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-code" className="panel-collapse collapse">
                          <div className="panel-body step-">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description">
                                      <h1 className="heading latoBlack mb-3">Kick-off</h1>
                                      <p className="latoRegular">
                                        We use modified Scrum for project management and delivery of
                                        each project.
                                      </p>
                                      <p className="latoRegular">
                                        <a href="/modified-scrum-model" className="mr-1">
                                          {' '}
                                          Read More
                                        </a>
                                        on why we modify the Scrum model for each project.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group" id="accordion">
                      {/* increment */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-increment"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/increment.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Increment &amp; Release Cycles</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-increment" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description col-11">
                                      <h1 className="heading latoBlack mb-3">
                                        Increment and Release cycles
                                      </h1>
                                      <p className="latoRegular">
                                        A sprint is typically one or two weeks long. At the end of
                                        each sprint we deliver the verified and tested increment
                                        (build) to the client along with the sprint report and QA
                                        report. We always appreciate and welcome client feedback.
                                        Any given feedback is discussed with the project team and
                                        then incorporated in future increments.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="panel-group" id="accordion">
                      {/* increment */}
                      <div className="panel panel-default">
                        <div className="panel-heading py-3">
                          <div className="panel-title  text-left">
                            <a
                              className="accordion-toggle collapsed expertise-heading"
                              data-toggle="collapse"
                              href="#collapse-milestone"
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require('../../assets/images/services/development-process/milestone.png')
                                    .default
                                }
                              />
                              <p className="description mb-0">Delivery Milestones</p>
                            </a>
                          </div>
                        </div>
                        <div id="collapse-milestone" className="panel-collapse collapse">
                          <div className="panel-body">
                            <div className="row">
                              <div className="col-12">
                                <div id="pre-kickoff-description" className="process-description">
                                  <div className="d-flex d-row">
                                    <div className="description">
                                      <h1 className="heading latoBlack mb-3">Milestone Delivery</h1>
                                      <p className="latoRegular">
                                        Once all the development work is completed and approved by
                                        the client we submit the game on iOS App Store and Android
                                        Play Store.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* dev process for mobile */}
            </div>
            {/* Game development Process */}
            {/* Feature project */}
            <section
              id="project"
              className="feature-project"
              style={{backgroundImage: `url(${beatbugs})`}}
            >
              <div className="container">
                <h1 className="heading text-center">
                  <div className="horizontal-line mr-3" />
                  Featured Project
                  <div className="horizontal-line ml-3" />
                </h1>
              </div>
              {/* Desktop Tablet Pro Large Screen */}
              <div className="container-fluid feature-project-desktop">
                <div className="container-xl position-relative">
                  <div className="position-absolute" style={{left: 0, zIndex: 20}}>
                    <div className="project beatbugs">
                      <h1 className="latoBlack project-heading pt-0 pb-0">
                        <img
                          src={
                            require('../../assets/images/services/game-development/beatbugs.png')
                              .default
                          }
                        />
                      </h1>
                      <p className="latoRegular project-description mb-0">
                        Ranked number 5 in Best-selling Music app category, Beat Bugs is an
                        interactive musical app from the Emmy Award-winning Beat Bugs series.
                      </p>
                      <a
                        target="_blank"
                        href="/portfolio/beatbugs"
                        className="btn-iot btn-success-case case-study"
                      >
                        View Case Study
                      </a>
                    </div>
                  </div>
                  <div className="position-absolute" style={{right: 0, zIndex: 20}}>
                    <div className="client-review" style={{height: '400px'}}>
                      <img
                        width="60px"
                        height="60px"
                        src={
                          require('../../assets/images/services/game-development/shaan.png').default
                        }
                      />
                      <h2 className="latoBold name mb-0">Shaan Kandawalla</h2>
                      <p className="latoBold designation mb-0">COFOUNDER, PLAYDATE DIGITAL</p>
                      <div className="background">
                        <p className="latoItalic review mb-0">
                          I give them an A-plus for project management and communication.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-img">
                    <div className="tab-carousel  d-flex justify-content-center align-items-center">
                      <div
                        id="carouselExampleIndicators"
                        className="carousel slide carousel-fade"
                        data-ride="carousel"
                        data-interval={3000}
                      >
                        <ol className="carousel-indicators">
                          <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={0}
                            className="active"
                          />
                          <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                          <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                        </ol>
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <img
                              className="app-shot"
                              src={
                                require('../../assets/images/services/game-development/beatbugs-first.png')
                                  .default
                              }
                              alt=""
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="app-shot"
                              src={
                                require('../../assets/images/services/game-development/beatbugs-second.png')
                                  .default
                              }
                              alt=""
                            />
                          </div>
                          <div className="carousel-item">
                            <img
                              className="app-shot"
                              src={
                                require('../../assets/images/services/game-development/beatbugs-third.png')
                                  .default
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Desktop Tablet Pro Large Screen */}
              {/* Mobile Screen */}
              <div className="container feature-project-mobile">
                <div className="row d-flex d-column justify-content-center">
                  <div className="col-12 float-left">
                    <div className="project ">
                      <h1 className="justify-content-start latoBlack project-heading mb-0">
                        <img
                          src={
                            require('../../assets/images/services/game-development/beatbugs.png')
                              .default
                          }
                        />
                      </h1>
                      <p className="latoRegular project-description">
                        Ranked number 5 in Best-selling Music app category, Beat Bugs is an
                        interactive musical app from the Emmy Award-winning Beat Bugs series.
                      </p>
                      <a
                        target="_blank"
                        href="/portfolio/beatbugs"
                        className="btn-iot btn-success case-study"
                      >
                        View Case Study
                      </a>
                    </div>
                    {/* Laptop Image */}
                    <div className="laptop">
                      <img
                        src={
                          require('../../assets/images/services/game-development/beatbugs-mobile.png')
                            .default
                        }
                      />
                    </div>
                    {/* Laptop Image */}
                    {/* Client Testimonial */}
                    <div className="text-center">
                      {/* Inline styling because this would take alot of classes */}
                      <div className="client-review" style={{height: '400px'}}>
                        <img
                          width={60}
                          height={60}
                          src={
                            require('../../assets/images/services/game-development/shaan.png')
                              .default
                          }
                        />
                        <h2 className="latoBold name mb-0">Shaan Kandawalla</h2>
                        <p className="latoBold designation mb-0">COFOUNDER, PLAYDATE DIGITAL</p>
                        <div className="background">
                          <p className="latoItalic review mb-0 text-left">
                            I give them an A-plus for project management and communication.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Client Testimonial */}
                  </div>
                </div>
              </div>
              {/* Mobile Screen */}
            </section>
            {/* Feature project */}
            {/* Client engagements */}
            <div id="client" className="container">
              <div className="client-engagement">
                <div className="row d-flex flex-row">
                  <div className="col-12">
                    <h1 className="heading latoBold">Client Engagements</h1>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/stick-sports-logo.png')
                            .default
                        }
                        alt="stick-sports-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={require('../../assets/images/services/projects/cb-logo.png').default}
                        alt="cb-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={require('../../assets/images/services/projects/gulp-logo.png').default}
                        alt="gulp-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/gamevice-logo.png').default
                        }
                        alt="gamevice-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/balljam-logo.png').default
                        }
                        alt="balljam-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/tarzan-logo.png').default
                        }
                        alt="tarzan-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/mathwizard-logo.png')
                            .default
                        }
                        alt="mathwizard-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-12 pr-lg-0">
                    <div className="box web-box">
                      <img
                        className="img-fluid"
                        src={
                          require('../../assets/images/services/projects/woody-logo.png').default
                        }
                        alt="woody-logo"
                      />
                      <div
                        className="overlay w-100 h-100 d-flex flex-column justify-content-end align-items-start  overlay-text pl-3"
                        onClick={() => navigate('/contact')}
                      >
                        <p className="latoRegular mb-0 info">For more information on this</p>
                        <p className="latoBlack contact">Contact Us</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Client ngagements */}
          </section>
        </div>
      </>
    );
  }
}

export default GameDevelopment;
