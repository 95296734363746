import React from 'react';
import Layout from '../../components/Shared/Layout';
import GameDevelopment from '../../components/DetailServices/gamedev';
import '../../assets/css/services.css';
import * as ReactGA from 'react-ga';

const $ = typeof window !== `undefined` ? require('jquery') : null;
// imprt 'bootstrap';

class GamePage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'Services/Game.Development',
    });
    ReactGA.pageview(this.props.location.pathname);
  }

  render() {
    return (
      <Layout>
        <GameDevelopment />
      </Layout>
    );
  }
}

export default GamePage;
